import React from 'react';
import CustomProvider from 'rsuite/esm/CustomProvider';
import { Particles } from './components/particles';
import { NotificationContainer } from 'react-notifications';
const Landing = React.lazy(() => import('./components/pages/landing'));
const Dashboard = React.lazy(() => import('./components/pages/dashboard'));
const Terms = React.lazy(() => import('./components/pages/terms'));
const Help = React.lazy(() => import('./components/pages/help'));
const Admin = React.lazy(() => import('./components/pages/admin'));
const Connect = React.lazy(() => import('./components/pages/connect'));
const RMkit = React.lazy(() => import('./components/pages/rmkit'));
const RMkitOLD = React.lazy(() => import('./components/pages/rmkit_old'));

import './style/style.less';
import { WebStorage } from './storage';
import { observer } from 'mobx-react';

export const App = observer(() => {
    const { page } = WebStorage;

    React.useEffect(() => {
        console.log('NEW PAGE', page);
    }, [page]);

    React.useEffect(() => {
        if (location.href.startsWith('http://127')) {
            location.replace(location.href.replace('http://', 'https://'));
        }
    }, []);

    return (
        <>
            <CustomProvider theme="dark">
                <Particles />
                {location.search.startsWith('?connect=') ? (
                    <Connect />
                ) : location.search.startsWith('?rmkit=') ? (
                    <RMkit />
                ) : location.search.startsWith('?rmkitold=') ? (
                    <RMkitOLD />
                ) : (
                    <>
                        {page === 'landing' ? <Landing /> : <></>}
                        {page === 'dashboard' ? <Dashboard /> : <></>}
                        {page === 'terms' ? <Terms /> : <></>}
                        {page.startsWith('help') ? <Help /> : <></>}
                        {page === 'admin' ? <Admin /> : <></>}
                    </>
                )}
                <NotificationContainer />
            </CustomProvider>
        </>
    );
});
