type ArrayLengthMutationKeys = 'splice' | 'push' | 'pop' | 'shift' | 'unshift';
type FixedLengthArray<T, L extends number, TObj = [T, ...Array<T>]> = Pick<
    TObj,
    Exclude<keyof TObj, ArrayLengthMutationKeys>
> & {
    readonly length: L;
    [I: number]: T;
    [Symbol.iterator]: () => IterableIterator<T>;
};

function getRandomInt(min: number, max: number) {
    return Math.floor(getRandomFloat(min, max));
}

function getRandomFloat(min: number, max: number) {
    if (max < min) {
        [max, min] = [min, max];
    }

    return Math.max(min, Math.min(min - 0.5 + Math.random() * (max - min + 1), max));
}

function randEl<T>(arr: T[]): T {
    if (!arr || arr.length == 0) {
        return null as any;
    }

    const newArr = [...arr];

    return newArr[getRandomInt(0, newArr.length - 1)];
}

function randElIndex<T>(arr: T[]): number;
function randElIndex<T>(arr: T[]): number {
    if (!arr || arr.length == 0) {
        return -1;
    }

    const newArr = [...arr];

    return getRandomInt(0, newArr.length - 1);
}

function makeid(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;

    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }

    return result;
}

export class SystemSharedConstruct {
    toBinary(string: string) {
        const codeUnits = new Uint16Array(string.length);

        for (let i = 0; i < codeUnits.length; i++) {
            codeUnits[i] = string.charCodeAt(i);
        }

        return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
    }

    fromBinary(encoded: string) {
        const binary = atob(encoded);
        const bytes = new Uint8Array(binary.length);

        for (let i = 0; i < bytes.length; i++) {
            bytes[i] = binary.charCodeAt(i);
        }

        return String.fromCharCode(...new Uint16Array(bytes.buffer));
    }

    makeid = makeid;
    sleep(time: number) {
        return new Promise<boolean>((resolve) => {
            setTimeout(() => {
                resolve(true);
            }, time);
        });
    }

    randomElement = randEl;
    randomElementIndex = randElIndex;
    getRandomInt = getRandomInt;
    getRandomFloat = getRandomFloat;
    get test() {
        return process.env.WORK_DEV == '1';
    }

    numberFormat(num: number, replaceIntEnd = true) {
        if (typeof num !== 'number') {
            num = Number(num);
        }

        let n = num.toFixed(2);

        if (replaceIntEnd) {
            n = n.replace('.00', '');
        }

        return n.replace(/.+?(?=\D|$)/, function (f) {
            return f.replace(/(\d)(?=(?:\d\d\d)+$)/g, '$1 ');
        });
    }

    get timestamp() {
        return Math.floor(this.timestampMS / 1000);
    }

    get timestampMS() {
        return Date.now();
    }

    get fullDateTime() {
        const dateTime = new Date();

        return `${this.digitFormat(dateTime.getDate())}.${this.digitFormat(
            dateTime.getMonth() + 1,
        )}.${this.digitFormat(dateTime.getFullYear())} ${this.digitFormat(
            dateTime.getHours(),
        )}:${this.digitFormat(dateTime.getMinutes())}`;
    }

    get dateNotTime() {
        const dateTime = new Date();

        return `${this.digitFormat(dateTime.getDate())}.${this.digitFormat(
            dateTime.getMonth() + 1,
        )}.${this.digitFormat(dateTime.getFullYear())}`;
    }

    digitFormat(number: string | number) {
        return `0${number}`.slice(-2);
    }

    /** Полная строка с датой и временем
     * @example 20.01.2020 12:00
     * @example 12:00 (Дата не рисуется если она сегодняшняя)
     * @example 20.01.2020 12:00 (В любом случае будет дата, если параметр full = true)
     */
    timeStampString(time: number, full = false) {
        const dateTimeNow = new Date();
        const dateTime = new Date(time * 1000);

        let res = `${this.digitFormat(dateTime.getHours())}:${this.digitFormat(
            dateTime.getMinutes(),
        )}`;

        if (
            full ||
            dateTimeNow.getDate() != dateTime.getDate() ||
            dateTimeNow.getMonth() != dateTime.getMonth() ||
            dateTimeNow.getFullYear() != dateTime.getFullYear()
        ) {
            res = `${this.digitFormat(dateTime.getDate())}.${this.digitFormat(
                dateTime.getMonth() + 1,
            )}${
                dateTimeNow.getFullYear() != dateTime.getFullYear() || full
                    ? `.${this.digitFormat(dateTime.getFullYear())}`
                    : ''
            } ${res}`;
        }

        return res;
    }

    secondsToString(duration: number) {
        // Hours, minutes and seconds
        const hrs = ~~(duration / 3600);
        const mins = ~~((duration % 3600) / 60);
        const secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = '';

        if (hrs > 0) {
            ret += `${hrs}:${mins < 10 ? '0' : ''}`;
        }

        ret += `${mins}:${secs < 10 ? '0' : ''}`;
        ret += `${secs}`;

        return ret;
    }

    msToString(duration: number) {
        const seconds = Math.floor(duration / 1000);
        let ret = this.secondsToString(seconds);

        let ms = (duration % 1000).toString();

        while (ms.length < 3) {
            ms = `0${ms}`;
        }

        ret += `.${ms}`;

        return ret;
    }

    chunkArray<T, Z extends number>(array: T[], chunkSize: Z): FixedLengthArray<T, Z>[] {
        return [].concat.apply(
            [],
            array.map(function (elem, i) {
                return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
            }),
        );
    }

    sortArray<T>(array: T[], type: 'DESC' | 'ASC' = 'DESC'): Array<T> {
        return array.sort((a, b) => {
            let res = 0;

            if (type !== 'DESC') {
                if (a < b) {
                    res = -1;
                } else if (a > b) {
                    res = 1;
                }
            } else {
                if (a < b) {
                    res = 1;
                } else if (a > b) {
                    res = -1;
                }
            }

            return res;
        });
    }

    sortArrayObjects<T>(array: T[], param: { id: keyof T; type: 'DESC' | 'ASC' }[]): Array<T> {
        return array.sort((a, b) => {
            let res = 0;

            param.map((q) => {
                if (res != 0) {
                    return;
                }

                const aval = a[q.id];
                const bval = b[q.id];

                if (q.type !== 'DESC') {
                    if (aval < bval) {
                        res = -1;
                    } else if (aval > bval) {
                        res = 1;
                    }
                } else {
                    if (aval < bval) {
                        res = 1;
                    } else if (aval > bval) {
                        res = -1;
                    }
                }
            });

            return res;
        });
    }

    toBuffer(ab: ArrayBuffer) {
        const buf = Buffer.alloc(ab.byteLength);
        const view = new Uint8Array(ab);

        for (let i = 0; i < buf.length; ++i) {
            buf[i] = view[i];
        }

        return buf;
    }
}

export const SystemShared = new SystemSharedConstruct();
