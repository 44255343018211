import { SystemShared } from '../../shared/system';

export const EventSystem = new (class {
    private list: { id: number; name: string; handle: (...args: any) => void }[] = [];

    on(name: string, handle: (...args: any) => void) {
        const id = SystemShared.getRandomInt(1999999999, 19999999999);

        this.list.push({ id, name, handle });

        return {
            destroy: () => {
                const index = this.list.findIndex((q) => q.id === id);

                if (index > -1) {
                    this.list.splice(index, 1);
                }
            },
        };
    }

    emit(name: string, ...args: any) {
        this.list.forEach((q) => {
            if (q.name === name) {
                q.handle(...args);
            }
        });
    }

    setPage(page: WebPages) {
        this.emit('setPage', page);
    }
})();

export type WebPages = 'landing' | 'dashboard' | 'terms' | 'help' | 'admin' | 'connect' | 'rmkit';
