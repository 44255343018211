import { observable, action, makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import { UserDataWeb } from '../shared/userdataweb';
import { langType } from '../src/lang';
import { EventSystem, WebPages } from './components/events';
import { PRODUCTS_LIST, ProductID, ProductPricePeriods } from '../shared/products';
import axios from 'axios';
import { authTypes } from '../shared/platforms';

export const pages: WebPages[] = ['landing', 'dashboard', 'terms', 'help', 'admin', 'connect'];

class WebStorageClass {
    constructor() {
        makeAutoObservable(this);
    }

    @observable userDataUpdateTimestamp = 0;
    @observable userData: UserDataWeb | undefined = undefined;
    @observable lang: langType = 'en';
    @observable private _cart: { type: ProductID; id: number; index: number }[] = [];

    get cartAmount() {
        let amount = 0;

        this.cart.forEach((itm) => {
            const index = itm.index;
            const q = ProductPricePeriods[index];

            if (!q) {
                return;
            }

            const cfg = PRODUCTS_LIST.find((a) => a.type === itm.type);

            if (!cfg) {
                return undefined;
            }

            const sum = parseFloat(
                (cfg.price * q.multiple - ((cfg.price * q.multiple) / 100) * q.percent).toFixed(2),
            );

            amount += sum;
        });

        return amount;
    }

    get cartAmountNoDiscount() {
        let amount = 0;

        this.cart.forEach((itm) => {
            const index = itm.index;
            const q = ProductPricePeriods[index];

            if (!q) {
                return;
            }

            const cfg = PRODUCTS_LIST.find((a) => a.type === itm.type);

            if (!cfg) {
                return undefined;
            }

            const sum = parseFloat((cfg.price * q.multiple).toFixed(2));

            amount += sum;
        });

        return amount;
    }

    get cart() {
        return this._cart;
    }

    set cart(val) {
        this.setCartData(val);
    }

    @action isExistInCart = (order_id: number) => {
        return !!this.cart.find((q) => q.id === order_id);
    };

    @action setCartData = (data: WebStorageClass['cart']) => {
        this._cart = data;
        localStorage.setItem('cartData', JSON.stringify(data));
    };

    @action addCartItem = (type: ProductID, id: number, index: number) => {
        this.setCartData([...this.cart.filter((q) => q.id !== id), { type, id, index }]);
    };

    @action setUserData = (
        data: undefined | UserDataWeb | ((old: WebStorageClass['userData']) => UserDataWeb),
    ) => {
        if (typeof data === 'function') {
            const newData = data(this.userData);

            this.userData = newData;
        } else {
            this.userData = data as any;
        }
    };

    @action setLastNotify = (id: string) => {
        if (this.userData) {
            this.userData.lastNotify = id;
        }
    };

    @action setLastNews = (id: string) => {
        if (this.userData) {
            this.userData.lastNews = id;
        }
    };

    @action setNotifyStatus = (id: (typeof authTypes)[number]['id'], status: boolean) => {
        if (this.userData) {
            this.userData.notifyList = this.userData.notifyList.filter((q) => q !== id);

            if (status) {
                this.userData.notifyList.push(id);
            }
        }
    };

    userDataBlock = (): [WebStorageClass['userData'], WebStorageClass['setUserData']] => {
        return [this.userData, this.setUserData];
    };

    @observable page: WebPages = 'landing';

    getPage = () => {
        return (
            pages.find(
                (q) => location.hash.includes(`#${q}`) || location.pathname.includes(`/${q}`),
            ) || 'landing'
        );
    };

    @action setPage = (data: WebPages | ((old: WebStorageClass['page']) => WebPages)) => {
        if (typeof data === 'function') {
            const newData = data(this.page);

            this.page = newData;
        } else {
            this.page = data;
        }
    };

    @action setPageAny = (data: string | ((old: string) => string)) => {
        if (typeof data === 'function') {
            const newData = data(this.page);

            this.page = newData as any;
        } else {
            this.page = data as any;
        }
    };

    ApiRequest = (
        path: string,
        query?: { [key: string]: any },
        userDataP?: { id: number; token: string },
    ) => {
        const dataP = userDataP || this.userData;

        const url = `${path}?${[
            ...Object.entries(dataP ? { id: dataP.id, token: dataP.token } : {}),
            ...Object.entries(query || {}),
        ]
            .map((q) => `${q[0]}=${q[1]}`)
            .join('&')}`;

        let text = String(url);

        if (dataP) {
            text = text.replace(dataP.token, '**********');
        }

        console.log(`Request: ${text}`);

        return new Promise<
            | { status: false; networkErr?: boolean; error_message: string; [key: string]: any }
            | { status: true; [key: string]: any }
        >((resolve) => {
            if (
                query &&
                (Object.keys(query).includes('id') || Object.keys(query).includes('token'))
            ) {
                console.error('ApiRequest', 'Incorrect query param');

                return resolve({ status: false, error_message: 'Incorrect query param' });
            }

            fetch(url)
                .then((res) => {
                    res.json()
                        .then((data) => {
                            resolve(data);
                        })
                        .catch((err) => {
                            resolve({ status: false, error_message: 'Error callback' });
                        });
                })
                .catch((err) => {
                    resolve({ status: false, error_message: 'Error callback', networkErr: true });
                });
        });
    };

    ApiRequestPost = (
        path: string,
        data?: { [key: string]: string },
        userDataP?: { id: number; token: string },
    ) => {
        const dataP = userDataP || this.userData;

        const params = {
            ...data,
            id: String(dataP?.id),
            token: String(dataP?.token),
        };

        const formData = new FormData();

        Object.entries(params)
            .filter((q) => q)
            .forEach((q) => {
                formData.append(q[0], q[1]);
            });

        return new Promise<
            | { status: false; networkErr?: boolean; error_message: string; [key: string]: any }
            | { status: true; [key: string]: any }
        >((resolve) => {
            if (data && (Object.keys(data).includes('id') || Object.keys(data).includes('token'))) {
                console.error('ApiRequest', 'Incorrect query param');

                return resolve({ status: false, error_message: 'Incorrect query param' });
            }

            axios
                .post(path, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    resolve({ status: false, error_message: 'Error callback', networkErr: true });
                });
        });
    };

    ApiLoadUserData = (data?: { id: number; token: string }) => {
        this.ApiRequest(
            '/api/verifyToken',
            {
                referral: localStorage.getItem('referral'),
            },
            data,
        ).then((res) => {
            if (res.status === false) {
                if (!res.networkErr) {
                    this.setUserData(null as any);
                    localStorage.removeItem('userData');
                    console.log('Remove auth data');
                } else {
                    console.log('Network error');
                }
            } else {
                this.setUserData(res.data);
                localStorage.setItem('userData', JSON.stringify(res.data));
            }
        });
    };

    ApiLoadUserDataAuth = (token: string) => {
        return new Promise<boolean>((q) => {
            this.ApiRequest('/api/authToken', {
                referral: localStorage.getItem('referral'),
                tkn: token,
            }).then((res) => {
                if (res.status === false) {
                    q(false);

                    if (!res.networkErr) {
                        this.setUserData(null as any);
                        localStorage.removeItem('userData');
                        console.log('Remove auth data');
                    } else {
                        console.log('Network error');
                    }
                } else {
                    q(true);
                    this.setUserData(res.data);
                    localStorage.setItem('userData', JSON.stringify(res.data));
                }
            });
        });
    };
}

let lastSentLang: langType;

setInterval(() => {
    if (!WebStorage.userData) {
        return;
    }

    if (!WebStorage.lang) {
        return;
    }

    if (WebStorage.userData.lang !== WebStorage.lang && lastSentLang !== WebStorage.lang) {
        WebStorage.userData.lang = WebStorage.lang;
        lastSentLang = WebStorage.lang;

        WebStorage.ApiRequest('/api/setAccountLang', {
            lang: WebStorage.lang,
        });
    }
}, 1000);

if (location.search.startsWith('?referral=') || location.search.startsWith('?r=')) {
    const ids = (
        location.search
            .replace('?', '')
            .split('&')
            .map((q) => q.split('='))
            .find((q) => q[0] === 'referral' || q[0] === 'r') as any
    )[1] as string;

    if (ids) {
        localStorage.setItem('referral', ids);
        const url = `${location.protocol}//${location.host}/`;

        history.pushState({}, null as any, url);
    }
}

export const WebStorage = new WebStorageClass();

WebStorage.page = WebStorage.getPage();

if (WebStorage.page === 'admin') {
    const url = `${location.protocol}//${location.host}/#dashboard`;

    history.pushState({}, null as any, url);
    WebStorage.page = 'dashboard';
}

EventSystem.on('setPage', (page) => {
    const url = `${location.protocol}//${location.host}/#${page}`;

    history.pushState({}, null as any, url);
});

setInterval(() => {
    const newPage = WebStorage.getPage();

    if (WebStorage.page !== newPage) {
        WebStorage.setPage(newPage);
    }
}, 100);

setInterval(() => {
    if (WebStorage.userData) {
        WebStorage.ApiLoadUserData();
    }
}, 30000);

const search = decodeURI(location.search.replace('?', '')).split('&');
const searchString = search.find((q) => q.startsWith('data='))?.replace('data=', '');

if (!searchString) {
    const oldData = localStorage.getItem('userData');

    if (oldData) {
        const qData = JSON.parse(oldData);

        if (qData) {
            WebStorage.ApiLoadUserData(qData);
        }
    }
}

const oldCart = localStorage.getItem('cartData');

if (oldCart) {
    WebStorage.cart = JSON.parse(oldCart);
}
