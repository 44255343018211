import { Provider } from 'mobx-react';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { WebStorage } from './storage';

const container = document.getElementById('app');

if (container) {
    const root = createRoot(container);

    root.render(
        <Provider store={WebStorage}>
            <App />
        </Provider>,
    );
}
