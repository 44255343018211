import { langData } from '../src/lang';
import { PlatformID } from './platforms';

export type ProductID =
    | 'application'
    | 'streamalerts'
    | 'streamalertsds'
    | 'streamalertsfull'
    | 'rmavc';

export const PRODUCTS_LIST: {
    type: ProductID;
    name: langData;
    desc: langData;
    descShort: langData;
    /** Full detail text data */
    descExpand?: {
        text?: langData;
        reqs?: [langData, string][];
    };
    /** Price in USD */
    price: number;
    /** Subscribe duration (0 - Lifetime) */
    priceDuration: number;
    /** This product finished and user can order it */
    canOrder: boolean;
    linkHWID?: boolean;
    linkTelegram?: boolean;
    linkDiscord?: boolean;
    linkDiscordChannel?: boolean;
    linkDiscordRole?: langData;
    linkTelegramChannel?: boolean;
    inputText?: boolean;
    selectLang?: boolean;
    selectTwitch?: boolean;
    selectTrovo?: boolean;
    downloadButtons?: PlatformID[];
    platforms?: PlatformID[];
    showLanding?: boolean;
    connectionsDesc?: langData | langData[];
    changeInterval?: number[];
    canpause?: boolean;
    /** Add product to welcome bonus */
    welcomeBonus?: boolean;
    /** Custom buttons for override default */
    customButtons?: { name: langData; url: string; buttonClass?: string }[];
}[] = [
    {
        type: 'application',
        name: 'ProductNameApplication',
        desc: 'ProductNameApplicationDesc',
        descShort: 'ProductNameApplicationDescShort',
        price: 3.9,
        priceDuration: 30,
        canOrder: true,
        linkHWID: true,
        platforms: [
            'windows',
            /*'apple', */ 'twitch',
            'trovo',
            'streamlabs',
            'streamelements',
            'donatello',
            'donatepay',
            'donationalerts',
        ],
        downloadButtons: ['windows' /*'apple', */],
        showLanding: true,
        connectionsDesc: 'WebOrderEditNav_connectionsDesc_Streamkit',
        descExpand: {
            text: 'orderDescF',
            reqs: [
                ['orderSpecOS', 'Windows 10/11'],
                ['orderSpecCPU', '4 Core, 3.0 Ghz'],
                ['orderSpecRAM', 'DDR3 8GB (<900 MB usage)'],
                ['orderSpecGPU', 'GTX 1650 Super / RX 5500 XT'],
                ['orderSpecNet', 'Broadband Internet connection'],
            ],
        },
        welcomeBonus: true,
        // changeInterval: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
        type: 'streamalertsfull',
        name: 'ProductNameStreamAlertsFull',
        desc: 'ProductNameStreamAlertsFullDesc',
        descShort: 'ProductNameStreamAlertsFullDescShort',
        descExpand: { text: 'orderDescAlertsFull' },
        price: 0.7,
        priceDuration: 30,
        canOrder: true,
        inputText: true,
        selectLang: false,
        selectTwitch: true,
        selectTrovo: true,
        linkTelegram: true,
        linkTelegramChannel: true,
        linkDiscord: true,
        linkDiscordRole: 'discordRoleSelectStreamAlerts',
        linkDiscordChannel: true,
        platforms: ['telegram', 'discord', 'twitch', 'trovo'],
        showLanding: true,
        connectionsDesc: 'WebOrderEditNav_connectionsDesc',
        changeInterval: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        canpause: true,
        welcomeBonus: true,
    },
    {
        type: 'streamalerts',
        name: 'ProductNameStreamAlerts',
        desc: 'ProductNameStreamAlertsDesc',
        descShort: 'ProductNameStreamAlertsDescShort',
        descExpand: { text: 'orderDescAlertsTelegram' },
        price: 0.5,
        priceDuration: 30,
        canOrder: true,
        linkTelegram: true,
        linkTelegramChannel: true,
        inputText: true,
        selectLang: false,
        selectTwitch: true,
        selectTrovo: true,
        platforms: ['telegram', 'twitch', 'trovo'],
        connectionsDesc: 'WebOrderEditNav_connectionsDesc_Telegram',
        changeInterval: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        canpause: true,
    },
    {
        type: 'streamalertsds',
        name: 'ProductNameStreamAlertsDS',
        desc: 'ProductNameStreamAlertsDSDesc',
        descShort: 'ProductNameStreamAlertsDSDescShort',
        descExpand: { text: 'orderDescAlertsDiscord' },
        price: 0.5,
        priceDuration: 30,
        canOrder: true,
        inputText: true,
        selectLang: false,
        selectTwitch: true,
        selectTrovo: true,
        linkDiscord: true,
        linkDiscordRole: 'discordRoleSelectStreamAlerts',
        linkDiscordChannel: true,
        platforms: ['discord', 'twitch', 'trovo'],
        connectionsDesc: 'WebOrderEditNav_connectionsDesc_Discord',
        changeInterval: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        canpause: true,
    },
    {
        type: 'rmavc',
        name: 'ProductNameRMAVC',
        desc: 'ProductNameRMAVC_Desc',
        descShort: 'ProductNameRMAVC_DescShort',
        descExpand: {
            text: 'orderDesRMAVC',
            reqs: [
                ['orderSpecOS', 'Windows 10 >'],
                ['orderSpecCPU', '4 Core, 3.0 Ghz'],
                ['orderSpecRAM', 'DDR3 4GB (<150 MB usage)'],
            ],
        },
        price: 0,
        priceDuration: 30,
        canOrder: false,
        platforms: ['windows'],
        showLanding: true,
        customButtons: [
            {
                name: 'WebLandingOrder',
                url: 'https://github.com/XXanderWP/AppsVolumeControl',
                buttonClass: 'download',
            },
            {
                name: 'WebLandingOrderDownload',
                url: 'https://github.com/XXanderWP/AppsVolumeControl/releases/latest',
                buttonClass: 'manage',
            },
        ],
    },
];

export const ProductPricePeriods: { multiple: number; percent: number }[] = [
    { multiple: 1, percent: 0 },
    { multiple: 3, percent: 5 },
    { multiple: 6, percent: 11 },
    { multiple: 12, percent: 25 },
];
